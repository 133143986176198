"use client";

import { DeferredOpen } from "components/common/DeferredOpen";
import MyModal from "components/common/Modal";
import { ModalContentArea } from "components/layout/page/ContentArea";
import { createContext, useContext, useState, type ReactNode } from "react";
type Options = "yes" | "no" | "cancel";
type BasicConfirmDialog3 = {
  title?: string;
  message: ReactNode;
  yesButtonContents: ReactNode;
  noButtonContents: ReactNode;
  cancelButtonContents: ReactNode;
};
type DialogWithResolve3 = BasicConfirmDialog3 & {
  onResolve: (confirmed: Options) => Promise<void>;
};
const ConfirmContext = createContext<{
  dialogStack: DialogWithResolve3[];
  appendDialog: (dialog: DialogWithResolve3) => Promise<void>;
  popDialog: () => Promise<void>;
} | null>(null);
export function ConfirmContextProvider3({
  children
}: {
  children: ReactNode;
}) {
  const [dialogStack, setDialogStack] = useState<DialogWithResolve3[]>([]);
  const openDialog = dialogStack.length > 0 ? dialogStack[dialogStack.length - 1] : undefined;
  const cancelFn = () => {
    popDialog();
    openDialog!.onResolve("cancel");
  };
  const yesFn = () => {
    popDialog();
    openDialog!.onResolve("yes");
  };
  const noFn = () => {
    popDialog();
    openDialog!.onResolve("no");
  };
  const appendDialog = async (dialog: DialogWithResolve3) => {
    setDialogStack(old => [...old, dialog]);
  };
  const popDialog = async () => {
    if (dialogStack.length === 0) {
      return;
    }
    setDialogStack(old => [...old.slice(0, old.length - 1)]);
  };
  return <ConfirmContext.Provider value={{
    dialogStack: [],
    appendDialog: appendDialog,
    popDialog
  }} data-sentry-element="unknown" data-sentry-component="ConfirmContextProvider3" data-sentry-source-file="Confirm3.tsx">
      {children}
      <DeferredOpen isOpen={!!openDialog} data-sentry-element="DeferredOpen" data-sentry-source-file="Confirm3.tsx">
        {isOpen => {
        return !!openDialog && <MyModal isOpen={isOpen} onRequestClose={cancelFn} title={openDialog.title} takeover={true}>
                <ModalContentArea className="flex flex-col gap-4 sm:gap-6">
                  {openDialog.message}
                  <div className="flex flex-row items-center gap-4 sm:gap-6 justify-center">
                    <button className="button bg-red_error" onClick={noFn}>
                      {openDialog.noButtonContents}
                    </button>
                    <button className="button bg-green_primary" onClick={yesFn}>
                      {openDialog.yesButtonContents}
                    </button>

                    <button className="button" onClick={cancelFn}>
                      {openDialog.cancelButtonContents}
                    </button>
                  </div>
                </ModalContentArea>
              </MyModal>;
      }}
      </DeferredOpen>
    </ConfirmContext.Provider>;
}
export const useConfirm3 = () => {
  const dialogContext = useContext(ConfirmContext);
  return async (dialog: BasicConfirmDialog3) => {
    const defaultedDialog = dialog;
    return new Promise<Options>(resolve => {
      dialogContext!.appendDialog({
        ...defaultedDialog,
        onResolve: async (confirmed: Options) => {
          resolve(confirmed);
        }
      });
    });
  };
};